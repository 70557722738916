import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  graderList: {
    data: [],
    offset: -1,
    limit: 10,
    length: 0,
    total: 0,
    isLoading: false,
    hasMore: false
  },
  isLoadingGrader: false,
  isRefreshingGraderList: false,
  graderDetail: [],

  isCreateGraderSuccess: false,
  isCreateGraderFailed: false,
  isProcessCreateGrader: false,

  isUpdateGraderSuccess: false,
  isUpdateGraderFailed: false,
  isProcessUpdateGrader: false,

  isRemoveGraderSuccess: false,
  isProcessRemoveGrader: false,

  announcementList: [],
  isSwitchLanguageSuccess: false
};

export default function GraderReducer(state = initState, action) {

  switch (action.type) {

    
    case actionTypes.LOAD_GRADER_LIST:{
      let graderList = cloneDeep(state.graderList);
      
      graderList.isLoading = true;

      if (action.data.offset === 0) {
        graderList.data = [];
      }

      return {
        ...state,
        isLoadingGrader: true,
        graderList,
        isRefreshingGraderList: (action.data.offset > 0) ? true : false
      };
    }

    case actionTypes.LOAD_GRADER_LIST_SUCCESS: {
      let graderList = {};
      
      if (action.data.limit === undefined) {
        graderList = cloneDeep(state.graderList);
        graderList.data = action.data.data;
      } else {
        graderList.offset = -1;

        // First time load / Refresh
        if (action.data.offset === 0) {
          graderList.data = action.data.data;
          graderList.length = action.data.total_result;
        } else {
          if (action.data.total_result === 0) {
            graderList.data = [...state.graderList.data];
            graderList.offset = action.data.offset;
          } else {
            let temp = [...state.graderList.data];
            let old_key = Object.keys(temp);
            Object.keys(action.data.data).forEach((val) => {
              if (old_key.includes(val)) {
                temp.push(action.data.data[val]);
              }
            });

            graderList.data = temp;
            graderList.length = state.graderList.length + action.data.total_result;
          }
        }
        if (action.data.total_result < action.data.limit) {
          graderList.offset = graderList.length;
        }
      }
  
      graderList.isLoading = false;
      graderList.total = action.data.total;
      graderList.hasMore = false;
      
      if(graderList.length < graderList.total)
      {
        graderList.hasMore = true;
      }


      return {
        ...state,
        isLoadingGrader: false,
        graderList
      };
    }

    case actionTypes.LOAD_GRADER_LIST_FAIL:{
      let graderList = cloneDeep(state.orderHistoryList);
      graderList.isLoading = false;

      return {
        ...state,
        graderList,
        isLoadingGrader: false,
      };
    }

    

    case actionTypes.LOAD_GRADER_DETAIL: {
      return {
        ...state,
        graderDetail: [],
      };
    }

    case actionTypes.LOAD_GRADER_DETAIL_SUCCESS: {
      return {
        ...state,
        graderDetail: action.data,
      };
    }

    case actionTypes.LOAD_GRADER_DETAIL_FAIL: {
      return {
        ...state,
      };
    }

    case actionTypes.CREATE_GRADER: {
      return {
        ...state,
        isCreateGraderSuccess: false,
        isCreateGraderFailed: false,
        isProcessCreateGrader: true,
      };
    }

    case actionTypes.CREATE_GRADER_SUCCESS: {
      return {
        ...state,
        isCreateGraderSuccess: true,
        isProcessCreateGrader: false,
      };
    }

    case actionTypes.CREATE_GRADER_FAIL: {
      return {
        ...state,
        isCreateGraderFailed: true,
        isProcessCreateGrader: false,
      };
    }


    case actionTypes.UPDATE_GRADER: {
      return {
        ...state,
        isUpdateGraderSuccess: false,
        isUpdateGraderFailed: false,
        isProcessUpdateGrader: true,
      };
    }

    case actionTypes.UPDATE_GRADER_SUCCESS: {
      return {
        ...state,
        isUpdateGraderSuccess: true.valueOf,
        isProcessUpdateGrader: false,
      };
    }

    case actionTypes.UPDATE_GRADER_FAIL: {
      return {
        ...state,
        isUpdateGraderFailed: true,
        isProcessUpdateGrader: false,
      };
    }


    case actionTypes.REMOVE_GRADER: {
      return {
        ...state,
        isRemoveGraderSuccess: false,
        isProcessRemoveGrader: true,
      };
    }

    case actionTypes.REMOVE_GRADER_SUCCESS: {
      return {
        ...state,
        isRemoveGraderSuccess: true,
        isProcessRemoveGrader: false,
      };
    }

    case actionTypes.REMOVE_GRADER_FAIL: {
      return {
        ...state,
        isRemoveGraderSuccess: false,
        isProcessRemoveGrader: false,
      };
    }

    
    

    case actionTypes.LOAD_ANNOUNCEMENT: {
      return {
        ...state,
        announcementList: []
      };
    }

    case actionTypes.LOAD_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        announcementList: action.data
      };
    }

    case actionTypes.LOAD_ANNOUNCEMENT_FAIL: {
      return {
        ...state
      };
    }
       
      

    case actionTypes.SWITCH_LANGUAGE: {
      return {
        ...state,
        isSwitchLanguageSuccess: false
      };
    }

    case actionTypes.SWITCH_LANGUAGE_SUCCESS: {
      return {
        ...state,
        isSwitchLanguageSuccess: true
      };
    }

    case actionTypes.SWITCH_LANGUAGE_FAIL: {
      return {
        ...state,
        isSwitchLanguageSuccess: false
      };
    }

    

    default:
      return state;
  }
}
