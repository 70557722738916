import * as actionTypes from "./actionTypes";



export const loadPurchaseBillList = data => {
  
  return {
    type: actionTypes.LOAD_PURCHASE_BILL_LIST,
    data
  };
};

export const loadPurchaseBillListSuccess = data => {
  return {
    type: actionTypes.LOAD_PURCHASE_BILL_LIST_SUCCESS,
    data
  };
};

export const loadPurchaseBillListFail = () => {
  return {
    type: actionTypes.LOAD_PURCHASE_BILL_LIST_FAIL
  };
};


export const getPurchaseBillDumpExpress = (id, data) => {
  
  return {
    type: actionTypes.GET_PURCHASE_BILL_DUMP_EXPRESS,
    id,
    data
  };
};

export const getPurchaseBillDumpExpressSuccess = data => {
  return {
    type: actionTypes.GET_PURCHASE_BILL_DUMP_EXPRESS_SUCCESS,
    data
  };
};

export const getPurchaseBillDumpExpressFail = () => {
  return {
    type: actionTypes.GET_PURCHASE_BILL_DUMP_EXPRESS_FAIL
  };
};


export const getPurchaseBillDumpRecycling = (id, data) => {
  
  return {
    type: actionTypes.GET_PURCHASE_BILL_DUMP_RECYCLING,
    id,
    data
  };
};

export const getPurchaseBillDumpRecyclingSuccess = data => {
  return {
    type: actionTypes.GET_PURCHASE_BILL_DUMP_RECYCLING_SUCCESS,
    data
  };
};

export const getPurchaseBillDumpRecyclingFail = () => {
  return {
    type: actionTypes.GET_PURCHASE_BILL_DUMP_RECYCLING_FAIL
  };
};



export const loadStoreReceiptList = data => {
  
  return {
    type: actionTypes.LOAD_STORE_RECEIPT_LIST,
    data
  };
};

export const loadStoreReceiptListSuccess = data => {
  return {
    type: actionTypes.LOAD_STORE_RECEIPT_LIST_SUCCESS,
    data
  };
};

export const loadStoreReceiptListFail = () => {
  return {
    type: actionTypes.LOAD_STORE_RECEIPT_LIST_FAIL
  };
};


export const getStoreReceiptDumpExpress = (id, data) => {
  
  return {
    type: actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS,
    id,
    data
  };
};

export const getStoreReceiptDumpExpressSuccess = data => {
  return {
    type: actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS_SUCCESS,
    data
  };
};

export const getStoreReceiptDumpExpressFail = () => {
  return {
    type: actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS_FAIL
  };
};


export const getStoreReceiptDumpRecycling = (id, data) => {
  
  return {
    type: actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING,
    id,
    data
  };
};

export const getStoreReceiptDumpRecyclingSuccess = data => {
  return {
    type: actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING_SUCCESS,
    data
  };
};

export const getStoreReceiptDumpRecyclingFail = () => {
  return {
    type: actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING_FAIL
  };
};



export const loadPurchaseBillMonth = () => {
  
  return {
    type: actionTypes.LOAD_PURCHASE_BILL_MONTH,
  };
};

export const loadPurchaseBillMonthSuccess = data => {
  return {
    type: actionTypes.LOAD_PURCHASE_BILL_MONTH_SUCCESS,
    data
  };
};

export const loadPurchaseBillMonthFail = () => {
  return {
    type: actionTypes.LOAD_PURCHASE_BILL_MONTH_FAIL
  };
};


export const getPurchaseBillSummary = (data) => {
  
  return {
    type: actionTypes.LOAD_PURCHASE_BILL_SUMMARY,
    data
  };
};

export const getPurchaseBillSummarySuccess = data => {
  return {
    type: actionTypes.LOAD_PURCHASE_BILL_SUMMARY_SUCCESS,
    data
  };
};

export const getPurchaseBillSummaryFail = () => {
  return {
    type: actionTypes.LOAD_PURCHASE_BILL_SUMMARY_FAIL
  };
};




export const loadStockRecordSummary = (data) => {
  
  return {
    type: actionTypes.LOAD_STOCK_RECORD_SUMMARY,
    data
  };
};

export const loadStockRecordSummarySuccess = data => {
  return {
    type: actionTypes.LOAD_STOCK_RECORD_SUMMARY_SUCCESS,
    data
  };
};

export const loadStockRecordSummaryFail = () => {
  return {
    type: actionTypes.LOAD_STOCK_RECORD_SUMMARY_FAIL
  };
};



export const loadStockRecordList = data => {
  
  return {
    type: actionTypes.LOAD_STOCK_RECORD_LIST,
    data
  };
};

export const loadStockRecordListSuccess = data => {
  return {
    type: actionTypes.LOAD_STOCK_RECORD_LIST_SUCCESS,
    data
  };
};

export const loadStockRecordListFail = () => {
  return {
    type: actionTypes.LOAD_STOCK_RECORD_LIST_FAIL
  };
};



export const loadMonthlyStockReport = data => {
  
  return {
    type: actionTypes.LOAD_MONTHLY_STOCK_REPORT,
    data
  };
};

export const loadMonthlyStockReportSuccess = data => {
  return {
    type: actionTypes.LOAD_MONTHLY_STOCK_REPORT_SUCCESS,
    data
  };
};

export const loadMonthlyStockReportFail = () => {
  return {
    type: actionTypes.LOAD_MONTHLY_STOCK_REPORT_FAIL
  };
};



export const getMonthlyStockReportDoc = data => {
  
  return {
    type: actionTypes.GET_MONTHLY_STOCK_REPORT_DOC,
    data
  };
};

export const getMonthlyStockReportDocSuccess = () => {
  return {
    type: actionTypes.GET_MONTHLY_STOCK_REPORT_DOC_SUCCESS
  };
};

export const getMonthlyStockReportDocFail = () => {
  return {
    type: actionTypes.GET_MONTHLY_STOCK_REPORT_DOC_FAIL
  };
};



export const dailySettlementDetail = data => {
  
  return {
    type: actionTypes.LOAD_DAILY_SETTLEMENT_DETAIL,
    data
  };
};

export const dailySettlementDetailSuccess = data => {
  return {
    type: actionTypes.LOAD_DAILY_SETTLEMENT_DETAIL_SUCCESS,
    data
  };
};

export const dailySettlementDetailFail = () => {
  return {
    type: actionTypes.LOAD_DAILY_SETTLEMENT_DETAIL_FAIL
  };
};



export const completeDailySettlement = data => {
  
  return {
    type: actionTypes.COMPLETE_DAILY_SETTLEMENT,
    data
  };
};

export const completeDailySettlementSuccess = () => {
  return {
    type: actionTypes.COMPLETE_DAILY_SETTLEMENT_SUCCESS
  };
};

export const completeDailySettlementFail = () => {
  return {
    type: actionTypes.COMPLETE_DAILY_SETTLEMENT_FAIL
  };
};
