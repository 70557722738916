import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  uuid: null,
  grader_id: null,
  jwtToken: null,
  jwtTokenRefresh: null,
  profileData: {},
  isLoggedIn: false,
  isDeviceRegistered: false,
  processDeviceRegistered: false,
  isLoading: false,
  isUnderMaintenance: false,
  proceedRegister: true,
  proceedRegisterParams: {contact_no: "", veri_code: ""},
  proceedFirstLogin: true,
  proceedFirstLoginParams: {contact_no: "", veri_code: ""},

  toggleChangePass: false,
  forgotPasswordSuccess: false,
  
  newContentAvailable: false,
  versionUpdateList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
    }
  },
  isSMSSendSuccess: false,
  isSMSSendFail: false,
  isProcessSMSSend: false,
  sendSMSErrorMsg: null,
  smsCode: null,
  isFirstLoginSuccess: false,
  isFirstLoginFailed: false,
  isProcessFirstLogin: false,
  firstLoginErrorMsg: "",
  isSignupSuccess: false,
  isSignupFailed: false,
  signup_verify_email: false,
  isVerifySMSSuccess: false,
  verifySMSErrorMsg: null,
  isVerifySMSFail: false,
  accPendingApproval: null,
  isVerifyAccountSuccess: false,
  isUpdateProfileSuccess: false,
  isProcessUpdateProfile: false,
  isUploadProfileSuccess: false,
  isProcessUploadProfile: false,
  isDelProfileSuccess: false,
  isProcessLogout: false,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    
    case actionTypes.SET_DEVICE_UUID:
      return {
        ...state,
        uuid: action.uuid,
      };

    case actionTypes.SET_UNDER_MAINTENANCE:
      return {
        ...state,
        isUnderMaintenance: true,
      };
      

    case actionTypes.PROCEED_SIGNUP:
      return {
        ...state,
        proceedRegister: true,
        proceedRegisterParams: action.data
      };
      

    case actionTypes.PROCEED_FIRST_LOGIN:
      return {
        ...state,
        proceedFirstLogin: true,
        proceedFirstLoginParams: action.data
      };
      
    case actionTypes.DEVICE_REGISTER:
      return {
        ...state,
        isLoggedIn: false,
        isDeviceRegistered: false,
        processDeviceRegistered: true,
        grader_id: null,
        jwtToken: "",
        jwtTokenRefresh: "",
        isUnderMaintenance: false,        
        proceedRegister: false,        
        proceedFirstLogin: false,
      };

    case actionTypes.DEVICE_REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        isDeviceRegistered: false,
        processDeviceRegistered: false,
        grader_id: null,
        jwtToken: "",
        jwtTokenRefresh: ""
      };

    case actionTypes.DEVICE_REGISTER_SUCCESS:
      let isLoggedIn = false;
      let grader_id = (action.data && action.data.data && Object.keys(action.data.data).includes("Grader") && !action.data.data.Grader.force_logout) ? action.data.data.grader_id : null

      let profileData = { ...state.profileData };
      if (grader_id) {
        profileData = action.data.data.Grader;
      }

      if (
        action.data && action.data.data && action.data.data.grader_id && action.data.data.grader_id !== null && !action.data.data.Grader.force_logout
      ) {
        isLoggedIn = true;
      }
      
      return {
        ...state,
        isLoggedIn,
        isDeviceRegistered: true,
        processDeviceRegistered: false,
        grader_id,
        jwtToken: action.data.token,
        jwtTokenRefresh: action.data.refresh_token,
        profileData,
      };


    
    case actionTypes.SEND_SMS:
      return {
        ...state,
        isProcessSMSSend: true,
        isSMSSendSuccess: false,
        isSMSSendFail: false,
        smsCode: null,
        isVerifySMSFail: false,
        verifySMSErrorMsg: null,
      };

    case actionTypes.SEND_SMS_SUCCESS:
      return {
        ...state,
        isProcessSMSSend: false,
        isSMSSendSuccess: true,
        smsCode: (action.data) ? action.data.code : null
      };

    case actionTypes.SEND_SMS_FAIL:
      return {
        ...state,
        isProcessSMSSend: false,
        isSMSSendFail: true,
        smsCode: null,
        sendSMSErrorMsg: (action.err_msg) ? action.err_msg : null
      };


    case actionTypes.VERIFY_SMS:
      return {
        ...state,
        isVerifySMSSuccess: false,
        isVerifySMSFail: false,
        accPendingApproval: null
      };

    case actionTypes.VERIFY_SMS_SUCCESS:
      return {
        ...state,
        isVerifySMSSuccess: true,
        smsCode: null
      };

    case actionTypes.VERIFY_SMS_FAIL:
      return {
        ...state,
        isVerifySMSFail: true,
        verifySMSErrorMsg: (action.err_msg) ? action.err_msg : null
      };

    case actionTypes.VERIFY_SMS_PENDING_APPROVAL:
      return {
        ...state,
        isVerifySMSFail: true,
        accPendingApproval: action.data
      };
      
      
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        jwtToken: action.token,
        jwtTokenRefresh: action.refresh_token,
        profileData: action.profile,
      };
      
    case actionTypes.FIRST_LOGIN:
      return {
        ...state,
        isFirstLoginSuccess: false,
        isFirstLoginFailed: false,
        firstLoginErrorMsg: null,
        isProcessFirstLogin: true,
      };

    case actionTypes.FIRST_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        jwtToken: action.token,
        jwtTokenRefresh: action.refresh_token,
        profileData: action.profile,
        isFirstLoginSuccess: true,
        proceedFirstLogin: false,
        proceedFirstLoginParams: null,
        isProcessFirstLogin: false,
      };

    case actionTypes.FIRST_LOGIN_FAIL:
      return {
        ...state,
        isFirstLoginFailed: true,
        firstLoginErrorMsg: (action.err_msg) ? action.err_msg : null,
        isProcessFirstLogin: false,
      };

    case actionTypes.CLEAR_FIRST_LOGIN_ERRMSG:
      return {
        ...state,
        isFirstLoginFailed: false,
        firstLoginErrorMsg: null,
      };

    case actionTypes.DISMISS_FIRST_LOGIN:
      return {
        ...state,
        proceedFirstLogin: false,
        proceedFirstLoginParams: null,
        isProcessFirstLogin: false,
      };
      
    case actionTypes.SIGNUP:
      return {
        ...state,
        isSignupSuccess: false,
        isSignupFailed: false,
        signup_verify_email: false
      };

    case actionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        isSignupSuccess: true,
        // proceedRegister: false,
        // proceedRegisterParams: null,
        signup_verify_email: (action.data && action.data.data && action.data.data.verify_email) ? true : false
      };

    case actionTypes.SIGNUP_FAIL:
      return {
        ...state,
        isSignupFailed: true
      };

    case actionTypes.DISMISS_REGISTER_SUCCESS:
      return {
        ...state,
        proceedRegister: false,
        proceedRegisterParams: null,
      };
      

    case actionTypes.VERIFY_ACCOUNT:
      return {
        ...state,
        isVerifyAccountSuccess: false,
        verifyEmail: null
      };

    case actionTypes.VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
        isVerifyAccountSuccess: true,
        verifyEmail: action.data.email
      };

    case actionTypes.VERIFY_ACCOUNT_FAIL:
      return {
        ...state,
        isVerifyAccountSuccess: false
      };
      


    case actionTypes.LOGOUT:
      return {
        ...state,
        isProcessLogout: true
      };

    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        grader_id: null,
        jwtToken: null,
        jwtTokenRefresh: null,
        profileData: {},
        isLoggedIn: false,
        isProcessLogout: false,
      };
      
    case actionTypes.LOGOUT_FAIL:
      return {
        ...state,
        isProcessLogout: false
      };


    // Relogin
    case actionTypes.REFRESH_JWT_TOKEN_SUCCESS:
      return {
        ...state,
        jwtToken: action.token,
        jwtTokenRefresh: action.refresh_token
      };

    // Force Logout
    case actionTypes.FORCE_LOGOUT:
      return {
        ...state
      };
    case actionTypes.FORCE_LOGOUT_SUCCESS: {
      
      return {
        ...state,
        grader_id: null,
        jwtToken: null,
        jwtTokenRefresh: null,
        profileData: {},
        isLoggedIn: false,
      };
    }

    case actionTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profileData: action.data
      };


    case actionTypes.UPDATE_PROFILE:
      return {
        ...state,
        isUpdateProfileSuccess: false,        
        isProcessUpdateProfile: true,
      };

    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isUpdateProfileSuccess: true,       
        isProcessUpdateProfile: false,
      };

    case actionTypes.UPDATE_PROFILE_FAIL:
      return {
        ...state,
        isUpdateProfileSuccess: false,      
        isProcessUpdateProfile: false,
      };
      
      
    case actionTypes.UPLOAD_PROFILE_PIC:
      return {
        ...state,
        isUploadProfileSuccess: false,
        isProcessUploadProfile: true,
      };

    case actionTypes.UPLOAD_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        isUploadProfileSuccess: true,
        isProcessUploadProfile: false,
      };

    case actionTypes.UPLOAD_PROFILE_PIC_FAIL:
      return {
        ...state,
        isUploadProfileSuccess: false,
        isProcessUploadProfile: false,
      };

      
    case actionTypes.REMOVE_PROFILE_PIC:
      return {
        ...state,
        isDelProfileSuccess: false
      };

    case actionTypes.REMOVE_PROFILE_PIC_SUCCESS:
      return {
        ...state,
        isDelProfileSuccess: true
      };

    case actionTypes.REMOVE_PROFILE_PIC_FAIL:
      return {
        ...state,
        isDelProfileSuccess: false
      };




      
    case actionTypes.CHECK_VERSION: {
      
      return {
        ...state,
        newContentAvailable: false,
      };
    }

    case actionTypes.CHECK_VERSION_SUCCESS: {
      return {
        ...state,
        newContentAvailable: action.data.new_version_available,
      };
    }

    case actionTypes.VERSION_UPDATE_LIST: {
      let versionUpdateList = cloneDeep(state.versionUpdateList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        versionUpdateList.offset = action.data.offset;
        versionUpdateList.limit = action.data.limit;
      }

      return {
        ...state,
        versionUpdateList
      };
    }

    case actionTypes.VERSION_UPDATE_LIST_SUCCESS: {
      let versionUpdateList = cloneDeep(state.versionUpdateList);
      action.data.data.map(row => versionUpdateList.data.push(row))
      versionUpdateList.meta = {
        page: 1 + state.versionUpdateList.offset / state.versionUpdateList.limit,
        pageSize: state.versionUpdateList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.versionUpdateList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        versionUpdateList
      };
    }

    case actionTypes.VERSION_UPDATE_LIST_FAIL: {
      return {
        ...state
      };
    }

    default:
      return state;
  }
}
