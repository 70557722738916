import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/grader/zone";
  // return "http://192.168.1.12:3301/grader/zone"
};

export const loadZoneCount = data => {
  const url = `${baseURL()}/count`;
  return axios.post(url, data);
};

export const loadZoneList = data => {
  const url = `${baseURL()}/list`;
  return axios.post(url, data);
};

export const loadZoneDetail = (id, data) => {
  const url = `${baseURL()}/detail/${id}`;
  return axios.post(url, data);
};

export const createZone = (data) => {
  const url = `${baseURL()}/create`;
  return axios.post(url, data);
};

export const updateZone = (id, data) => {
  const url = `${baseURL()}/update/${id}`;
  return axios.post(url, data);
};

export const delZone = (id, data) => {
  const url = `${baseURL()}/remove/${id}`;
  return axios.post(url, data);
};


export const updateZonePlcement = (id, data) => {
  const url = `${baseURL()}/placement/${id}`;
  return axios.post(url, data);
};


export const updateZoneFullyFilled = (id, data) => {
  const url = `${baseURL()}/fullyFilled/${id}`;
  return axios.post(url, data);
};


export const updateZoneCollection = (id, data) => {
  const url = `${baseURL()}/collection/${id}`;
  return axios.post(url, data);
};


export const loadDumpsterHistoryList = (data) => {
  const url = `${baseURL()}/dumpHistory/list`;
  return axios.post(url, data);
};


export const loadExpressPackingList = (id, data) => {
  const url = `${baseURL()}/dumpHistory/packing/${id}`;
  return axios.post(url, data);
};


export const generateRecyclingPackingList = (id, data) => {
  const url = `${baseURL()}/generatePacking/${id}`;
  return axios.post(url, data);
};

export const loadRecyclingPackingHistoryList = (data) => {
  const url = `${baseURL()}/packingHistory/list`;
  return axios.post(url, data);
};

export const loadRecyclingPackingHistoryDetail = (id, data) => {
  const url = `${baseURL()}/packingHistory/load/${id}`;
  return axios.post(url, data);
};