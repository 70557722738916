import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/grader/order";
  // return "http://192.168.1.16:3301/grader/order"
};


export const getSettlementStatus = data => {
  const url = `${baseURL()}/settlement/status`;
  return axios.post(url, data);
};


export const scanUserQRcode = data => {
  const url = `${baseURL()}/dumpExpress/scanUserQRcode`;
  return axios.post(url, data);
};

export const startGrading = data => {
  const url = `${baseURL()}/dumpExpress/startGrading`;
  return axios.post(url, data);
};


/* ===== WALK IN ORDER  ===== */
export const placeWalkInOrder = data => {
  const url = `${baseURL()}/dumpExpress/placeOrderWeb`;
  return axios.post(url, data);
};

export const getPendingWalkInOrder = data => {
  const url = `${baseURL()}/dumpExpress/pending`;
  return axios.post(url, data);
};

export const cancelWalkInOrder = (id, data) => {
  const url = `${baseURL()}/dumpExpress/cancel/${id}`;
  return axios.post(url, data);
};


export const completeWalkInOrder = (id, data) => {
  const url = `${baseURL()}/dumpExpress/complete/${id}`;
  return axios.post(url, data);
};

export const gradeAgainCompletedWalkInOrder = (id, data) => {
  const url = `${baseURL()}/dumpExpress/gradeAgain/${id}`;
  return axios.post(url, data);
};

export const cancelCompletedWalkInOrder = (id, data) => {
  const url = `${baseURL()}/dumpExpress/updCancel/${id}`;
  return axios.post(url, data);
};

export const walkInOrderDetail = (id, data) => {
  const url = `${baseURL()}/dumpExpress/detail/${id}`;
  return axios.post(url, data);
};

export const addWalkInOrderRecycling = (id, data) => {
  const url = `${baseURL()}/dumpExpress/recycling/add/${id}`;
  return axios.post(url, data);
};

export const updateWalkInOrderRecycling = (id, recycling, data) => {
  const url = `${baseURL()}/dumpExpress/recycling/update/${id}/${recycling}`;
  return axios.post(url, data);
};

export const removeWalkInOrderRecycling = (id, data) => {
  const url = `${baseURL()}/dumpExpress/recycling/remove/${id}`;
  return axios.post(url, data);
};
/* ===== WALK IN ORDER  ===== */


export const loadOrderHistoryCount = data => {
  const url = `${baseURL()}/count`;
  return axios.post(url, data);
};

export const loadOrderHistoryList = data => {
  const url = `${baseURL()}/list`;
  return axios.post(url, data);
};

export const loadOrderHistoryDetail = (id, data) => {
  const url = `${baseURL()}/detail/${id}`;
  return axios.post(url, data);
};



/* ===== GRADING TASK  ===== */
export const loadGradingTaskList = (data) => {
  const url = `${baseURL()}/dumpRecycling/load`;
  return axios.post(url, data);
};


export const loadGradingTaskDetail = (id, data) => {
  const url = `${baseURL()}/dumpRecycling/detail/${id}`;
  return axios.post(url, data);
};


export const acceptGradingTask = (id, data) => {
  const url = `${baseURL()}/dumpRecycling/accept/${id}`;
  return axios.post(url, data);
};


export const rejectGradingTask = (id, data) => {
  const url = `${baseURL()}/dumpRecycling/reject/${id}`;
  return axios.post(url, data);
};


export const gradingTaskSetZone = (id, data) => {
  const url = `${baseURL()}/dumpRecycling/setZone/${id}`;
  return axios.post(url, data);
};


export const addGradingTaskRecycling = (id, data) => {
  const url = `${baseURL()}/dumpRecycling/recycling/add/${id}`;
  return axios.post(url, data);
};


export const updateGradingTaskRecycling = (id, recycling, data) => {
  const url = `${baseURL()}/dumpRecycling/recycling/update/${id}/${recycling}`;
  return axios.post(url, data);
};


export const removeGradingTaskRecycling = (id, data) => {
  const url = `${baseURL()}/dumpRecycling/recycling/remove/${id}`;
  return axios.post(url, data);
};


export const uploadGradingTaskDocument = (id, data) => {
  const url = `${baseURL()}/dumpRecycling/uploadDoc/${id}`;
  return axios.post(url, data);
};


export const completeGradingTask = (id, data) => {
  const url = `${baseURL()}/dumpRecycling/complete/${id}`;
  return axios.post(url, data);
};
/* ===== GRADING TASK  ===== */


export const registerUser = (data) => {
  const url = `${baseURL()}/registerUser`;
  return axios.post(url, data);
};

export const checkUserExist = (data) => {
  const url = `${baseURL()}/checkUserExist`;
  return axios.post(url, data);
};