import * as actionTypes from "./actionTypes";



export const getSettlementStatus = data => {
  
  return {
    type: actionTypes.GET_SETTLEMENT_STATUS,
    data
  };
};

export const getSettlementStatusSuccess = data => {
  
  return {
    type: actionTypes.GET_SETTLEMENT_STATUS_SUCCESS,
    data
  };
};

export const getSettlementStatusFail = () => {
  return {
    type: actionTypes.GET_SETTLEMENT_STATUS_FAIL
  };
};

/* ===== DUMP EXPRESS GRADING  ===== */
export const scanUserQRcode = data => {
  
  return {
    type: actionTypes.SCAN_USER_QR,
    data
  };
};

export const scanUserQRcodeSuccess = data => {
  
  return {
    type: actionTypes.SCAN_USER_QR_SUCCESS,
    data
  };
};

export const scanUserQRcodeFail = () => {
  return {
    type: actionTypes.SCAN_USER_QR_FAIL
  };
};


export const startGrading = data => {
  
  return {
    type: actionTypes.START_GRADING,
    data
  };
};

export const startGradingSuccess = data => {
  
  return {
    type: actionTypes.START_GRADING_SUCCESS,
    data
  };
};

export const startGradingFail = () => {
  return {
    type: actionTypes.START_GRADING_FAIL
  };
};
/* ===== DUMP EXPRESS GRADING  ===== */

/* ===== WALK IN ORDER  ===== */
export const placeWalkInOrder = data => {
  
  return {
    type: actionTypes.PLACE_WALKIN_ORDER,
    data
  };
};

export const placeWalkInOrderSuccess = data => {
  
  return {
    type: actionTypes.PLACE_WALKIN_ORDER_SUCCESS,
    data
  };
};

export const placeWalkInOrderFail = () => {
  return {
    type: actionTypes.PLACE_WALKIN_ORDER_FAIL
  };
};



export const getPendingWalkInOrder = data => {
  
  return {
    type: actionTypes.GET_PENDING_WALKIN_ORDER,
    data
  };
};

export const getPendingWalkInOrderSuccess = data => {
  return {
    type: actionTypes.GET_PENDING_WALKIN_ORDER_SUCCESS,
    data
  };
};

export const getPendingWalkInOrderFail = () => {
  return {
    type: actionTypes.GET_PENDING_WALKIN_ORDER_FAIL
  };
};


export const cancelWalkInOrder = (id,data) => {
  
  return {
    type: actionTypes.CANCEL_WALKIN_ORDER,
    id,
    data
  };
};

export const cancelWalkInOrderSuccess = () => {
  return {
    type: actionTypes.CANCEL_WALKIN_ORDER_SUCCESS,
  };
};

export const cancelWalkInOrderFail = () => {
  return {
    type: actionTypes.CANCEL_WALKIN_ORDER_FAIL
  };
};


export const completeWalkInOrder = (id,data) => {
  
  return {
    type: actionTypes.COMPLETE_WALKIN_ORDER,
    id,
    data
  };
};

export const completeWalkInOrderSuccess = () => {
  return {
    type: actionTypes.COMPLETE_WALKIN_ORDER_SUCCESS,
  };
};

export const completeWalkInOrderFail = () => {
  return {
    type: actionTypes.COMPLETE_WALKIN_ORDER_FAIL
  };
};



export const gradeAgainCompletedWalkInOrder = (id,data) => {
  
  return {
    type: actionTypes.GRADE_AGAIN_COMPLETED_WALKIN_ORDER,
    id,
    data
  };
};

export const gradeAgainCompletedWalkInOrderSuccess = (data) => {
  return {
    type: actionTypes.GRADE_AGAIN_COMPLETED_WALKIN_ORDER_SUCCESS,
    data
  };
};

export const gradeAgainCompletedWalkInOrderFail = () => {
  return {
    type: actionTypes.GRADE_AGAIN_COMPLETED_WALKIN_ORDER_FAIL
  };
};


export const cancelCompletedWalkInOrder = (id,data) => {
  
  return {
    type: actionTypes.CANCEL_COMPLETED_WALKIN_ORDER,
    id,
    data
  };
};

export const cancelCompletedWalkInOrderSuccess = (data) => {
  return {
    type: actionTypes.CANCEL_COMPLETED_WALKIN_ORDER_SUCCESS,
    data
  };
};

export const cancelCompletedWalkInOrderFail = () => {
  return {
    type: actionTypes.CANCEL_COMPLETED_WALKIN_ORDER_FAIL
  };
};


export const walkInOrderDetail = (id,data) => {
  
  return {
    type: actionTypes.WALKIN_ORDER_DETAIL,
    id,
    data
  };
};

export const walkInOrderDetailSuccess = data => {
  return {
    type: actionTypes.WALKIN_ORDER_DETAIL_SUCCESS,
    data
  };
};

export const walkInOrderDetailFail = () => {
  return {
    type: actionTypes.WALKIN_ORDER_DETAIL_FAIL
  };
};



export const addWalkInOrderRecycling = (id,data) => {
  
  return {
    type: actionTypes.WALKIN_ORDER_RECYCLING_ADD,
    id,
    data
  };
};

export const addWalkInOrderRecyclingSuccess = data => {
  return {
    type: actionTypes.WALKIN_ORDER_RECYCLING_ADD_SUCCESS,
    data
  };
};

export const addWalkInOrderRecyclingFail = () => {
  return {
    type: actionTypes.WALKIN_ORDER_RECYCLING_ADD_FAIL
  };
};

export const walkInOrderRecyclingPriceUpdConfirm = (data) => {
  return {
    type: actionTypes.WALKIN_ORDER_RECYCLING_PRICE_UPD_CONFIRM,
    data
  };
};


export const updateWalkInOrderRecycling = (id,recycling, data) => {
  
  return {
    type: actionTypes.WALKIN_ORDER_RECYCLING_UPDATE,
    id,
    recycling,
    data
  };
};

export const updateWalkInOrderRecyclingSuccess = data => {
  return {
    type: actionTypes.WALKIN_ORDER_RECYCLING_UPDATE_SUCCESS,
    data
  };
};

export const updateWalkInOrderRecyclingFail = () => {
  return {
    type: actionTypes.WALKIN_ORDER_RECYCLING_UPDATE_FAIL
  };
};



export const removeWalkInOrderRecycling = (id,data) => {
  
  return {
    type: actionTypes.WALKIN_ORDER_RECYCLING_REMOVE,
    id,
    data
  };
};

export const removeWalkInOrderRecyclingSuccess = () => {
  return {
    type: actionTypes.WALKIN_ORDER_RECYCLING_REMOVE_SUCCESS,
  };
};

export const removeWalkInOrderRecyclingFail = () => {
  return {
    type: actionTypes.WALKIN_ORDER_RECYCLING_REMOVE_FAIL
  };
};
/* ===== WALK IN ORDER  ===== */




export const loadOrderHistoryCount = data => {
  
  return {
    type: actionTypes.ORDER_HISTORY_COUNT,
    data
  };
};

export const loadOrderHistoryCountSuccess = data => {
  return {
    type: actionTypes.ORDER_HISTORY_COUNT_SUCCESS,
    data
  };
};

export const loadOrderHistoryCountFail = () => {
  return {
    type: actionTypes.ORDER_HISTORY_COUNT_FAIL
  };
};


export const loadOrderHistoryList = data => {
  
  return {
    type: actionTypes.ORDER_HISTORY_LIST,
    data
  };
};

export const loadOrderHistoryListSuccess = data => {
  return {
    type: actionTypes.ORDER_HISTORY_LIST_SUCCESS,
    data
  };
};

export const loadOrderHistoryListFail = () => {
  return {
    type: actionTypes.ORDER_HISTORY_LIST_FAIL
  };
};


export const loadOrderHistoryDetail = (id, data) => {
  
  return {
    type: actionTypes.ORDER_HISTORY_DETAIL,
    id,
    data
  };
};

export const loadOrderHistoryDetailSuccess = data => {
  return {
    type: actionTypes.ORDER_HISTORY_DETAIL_SUCCESS,
    data
  };
};

export const loadOrderHistoryDetailFail = () => {
  return {
    type: actionTypes.ORDER_HISTORY_DETAIL_FAIL
  };
};



/* ===== GRADING TASK  ===== */
export const loadGradingTaskList = data => {
  
  return {
    type: actionTypes.GRADING_TASK_LIST,
    data
  };
};

export const loadGradingTaskListSuccess = data => {
  return {
    type: actionTypes.GRADING_TASK_LIST_SUCCESS,
    data
  };
};

export const loadGradingTaskListFail = () => {
  return {
    type: actionTypes.GRADING_TASK_LIST_FAIL
  };
};


export const loadGradingTaskDetail = (id, data) => {
  
  return {
    type: actionTypes.GRADING_TASK_DETAIL,
    id,
    data
  };
};

export const loadGradingTaskDetailSuccess = data => {
  return {
    type: actionTypes.GRADING_TASK_DETAIL_SUCCESS,
    data
  };
};

export const loadGradingTaskDetailFail = () => {
  return {
    type: actionTypes.GRADING_TASK_DETAIL_FAIL
  };
};



export const acceptGradingTask = (id, data) => {
  
  return {
    type: actionTypes.GRADING_TASK_ACCEPT,
    id,
    data
  };
};

export const acceptGradingTaskSuccess = () => {
  return {
    type: actionTypes.GRADING_TASK_ACCEPT_SUCCESS
  };
};

export const acceptGradingTaskFail = () => {
  return {
    type: actionTypes.GRADING_TASK_ACCEPT_FAIL
  };
};



export const rejectGradingTask = (id, data) => {
  
  return {
    type: actionTypes.GRADING_TASK_REJECT,
    id,
    data
  };
};

export const rejectGradingTaskSuccess = () => {
  return {
    type: actionTypes.GRADING_TASK_REJECT_SUCCESS
  };
};

export const rejectGradingTaskFail = () => {
  return {
    type: actionTypes.GRADING_TASK_REJECT_FAIL
  };
};



export const gradingTaskSetZone = (id, data) => {
  
  return {
    type: actionTypes.GRADING_TASK_SET_ZONE,
    id,
    data
  };
};

export const gradingTaskSetZoneSuccess = () => {
  return {
    type: actionTypes.GRADING_TASK_SET_ZONE_SUCCESS
  };
};

export const gradingTaskSetZoneFail = () => {
  return {
    type: actionTypes.GRADING_TASK_SET_ZONE_FAIL
  };
};


export const addGradingTaskRecycling = (id, data) => {
  
  return {
    type: actionTypes.GRADING_TASK_RECYCLING_ADD,
    id,
    data
  };
};

export const addGradingTaskRecyclingSuccess = data => {
  return {
    type: actionTypes.GRADING_TASK_RECYCLING_ADD_SUCCESS,
    data
  };
};

export const addGradingTaskRecyclingFail = () => {
  return {
    type: actionTypes.GRADING_TASK_RECYCLING_ADD_FAIL
  };
};


export const gradingTaskRecyclingPriceUpdConfirm = (data) => {
  return {
    type: actionTypes.GRADING_TASK_RECYCLING_PRICE_UPD_CONFIRM,
    data
  };
};


export const updateGradingTaskRecycling = (id, recycling, data) => {
  
  return {
    type: actionTypes.GRADING_TASK_RECYCLING_UPDATE,
    id,
    recycling,
    data
  };
};

export const updateGradingTaskRecyclingSuccess = () => {
  return {
    type: actionTypes.GRADING_TASK_RECYCLING_UPDATE_SUCCESS
  };
};

export const updateGradingTaskRecyclingFail = () => {
  return {
    type: actionTypes.GRADING_TASK_RECYCLING_UPDATE_FAIL
  };
};


export const removeGradingTaskRecycling = (id, data) => {
  
  return {
    type: actionTypes.GRADING_TASK_RECYCLING_REMOVE,
    id,
    data
  };
};

export const removeGradingTaskRecyclingSuccess = () => {
  return {
    type: actionTypes.GRADING_TASK_RECYCLING_REMOVE_SUCCESS
  };
};

export const removeGradingTaskRecyclingFail = () => {
  return {
    type: actionTypes.GRADING_TASK_RECYCLING_REMOVE_FAIL
  };
};



export const uploadGradingTaskDocument = (id, data) => {
  
  return {
    type: actionTypes.GRADING_TASK_UPLOAD_DOC,
    id,
    data
  };
};

export const uploadGradingTaskDocumentSuccess = () => {
  return {
    type: actionTypes.GRADING_TASK_UPLOAD_DOC_SUCCESS
  };
};

export const uploadGradingTaskDocumentFail = () => {
  return {
    type: actionTypes.GRADING_TASK_UPLOAD_DOC_FAIL
  };
};




export const completeGradingTask = (id) => {
  
  return {
    type: actionTypes.COMPLETE_GRADING_TASK,
    id
  };
};

export const completeGradingTaskSuccess = () => {
  return {
    type: actionTypes.COMPLETE_GRADING_TASK_SUCCESS
  };
};

export const completeGradingTaskFail = () => {
  return {
    type: actionTypes.COMPLETE_GRADING_TASK_FAIL
  };
};
/* ===== GRADING TASK  ===== */



export const registerUser = (data) => {
  
  return {
    type: actionTypes.REGISTER_USER,
    data
  };
};

export const registerUserSuccess = (data) => {
  return {
    type: actionTypes.REGISTER_USER_SUCCESS,
    data
  };
};

export const registerUserFail = () => {
  return {
    type: actionTypes.REGISTER_USER_FAIL
  };
};



export const checkUserExist = (data) => {
  
  return {
    type: actionTypes.CHECK_USER_EXIST,
    data
  };
};

export const checkUserExistSuccess = (data) => {
  return {
    type: actionTypes.CHECK_USER_EXIST_SUCCESS,
    data
  };
};

export const checkUserExistFail = () => {
  return {
    type: actionTypes.CHECK_USER_EXIST_FAIL
  };
};


export const resetUserExist = () => {
  
  return {
    type: actionTypes.RESET_USER_EXIST
  };
};


export const resetUserExistSuccess = () => {
  
  return {
    type: actionTypes.RESET_USER_EXIST_SUCCESS
  };
};