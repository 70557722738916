import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  purchaseBillList: {
    data: [],
    offset: -1,
    limit: 10,
    length: 0,
    total: 0,
    isLoading: false,
    hasMore: false
  },
  isLoadingPurchaseBillList: false,
  isRefreshingPurchaseBillList: false, 

  purchaseBillDumpExpress: null,
  purchaseBillDumpRecycling: null,
  isLoadingPurchaseBill: false,

  storeReceiptList: {
    data: [],
    offset: -1,
    limit: 10,
    length: 0,
    total: 0,
    isLoading: false,
    hasMore: false
  },
  isLoadingStoreReceiptList: false,
  isRefreshingStoreReceiptList: false, 
  storeReceiptDumpExpress: null,
  storeReceiptDumpRecycling: null,
  isLoadingStoreReceipt: false,

  purchaseBillMonth: {},
  isLoadingPurchaseBillMonth: false,
  purchaseBillSummary: null,
  isLoadingPurchaseBillSummary: false,

  
  stockRecordSummary: null,
  isLoadingStockRecordSummary: true,
  storeRecordList: {
    data: [],
    offset: -1,
    limit: 10,
    length: 0,
    total: 0,
    isLoading: false,
    hasMore: false
  },
  isLoadingStoreRecordList: false,
  isRefreshingStoreRecordList: false, 
  
  monthlyStockReportList: [],
  isLoadingMonthlyStockReportList: false,
  isLoadingMonthlyStockReportDoc: false,

  dailySettlement: null,
  isLoadingDailySettlementDetail: false,

  isUpdateDailySettlementSuccess: false,
  isUpdateDailySettlementFailed: false,
  isProcessUpdateDailySettlement: false,
};

export default function ReportReducer(state = initState, action) {

  switch (action.type) {

    
    case actionTypes.LOAD_PURCHASE_BILL_LIST:{
      let purchaseBillList = cloneDeep(state.purchaseBillList);
  
      purchaseBillList.isLoading = true;

      if (action.data.offset === 0) {
        purchaseBillList.data = [];
      }

      return {
        ...state,
        isLoadingPurchaseBillList: true,
        purchaseBillList,
        isRefreshingPurchaseBillList: (action.data.offset > 0) ? true : false
      };
    }

    case actionTypes.LOAD_PURCHASE_BILL_LIST_SUCCESS: {
      let purchaseBillList = {};
      
      if (action.data.limit === undefined) {
        purchaseBillList = cloneDeep(state.purchaseBillList);
        purchaseBillList.data = action.data.data;
      } else {
        purchaseBillList.offset = -1;

        // First time load / Refresh
        if (action.data.offset === 0) {
          purchaseBillList.data = action.data.data;
          purchaseBillList.length = action.data.total_result;
        } else {
          if (action.data.total_result === 0) {
            purchaseBillList.data = [...state.purchaseBillList.data];
            purchaseBillList.offset = action.data.offset;
          } else {
            let temp = [...state.purchaseBillList.data];
            let old_key = Object.keys(temp);
            Object.keys(action.data.data).forEach((val) => {
              if (old_key.includes(val)) {
                temp.push(action.data.data[val]);
              }
            });

            purchaseBillList.data = temp;
            purchaseBillList.length = state.purchaseBillList.length + action.data.total_result;
          }
        }
        if (action.data.total_result < action.data.limit) {
          purchaseBillList.offset = purchaseBillList.length;
        }
      }
  
      purchaseBillList.isLoading = false;
      purchaseBillList.total = action.data.total;
      purchaseBillList.hasMore = false;
      
      if(purchaseBillList.length < purchaseBillList.total)
      {
        purchaseBillList.hasMore = true;
      }

      return {
        ...state,
        isLoadingPurchaseBillList: false,
        purchaseBillList
      };
    }

    case actionTypes.LOAD_PURCHASE_BILL_LIST_FAIL:{
      let purchaseBillList = cloneDeep(state.purchaseBillList);
      purchaseBillList.isLoading = false;

      return {
        ...state,
        purchaseBillList,
        isLoadingPurchaseBillList: false,
      };
    }



    case actionTypes.GET_PURCHASE_BILL_DUMP_EXPRESS: {
      return {
        ...state,
        purchaseBillDumpExpress: null,
        isLoadingPurchaseBill: true,
      };
    }

    case actionTypes.GET_PURCHASE_BILL_DUMP_EXPRESS_SUCCESS: {
      return {
        ...state,
        purchaseBillDumpExpress: action.data,
        isLoadingPurchaseBill: false,
      };
    }

    case actionTypes.GET_PURCHASE_BILL_DUMP_EXPRESS_FAIL: {
      return {
        ...state,
        isLoadingPurchaseBill: false,
      };
    }    
    

    case actionTypes.GET_PURCHASE_BILL_DUMP_RECYCLING: {
      return {
        ...state,
        purchaseBillDumpRecycling: null,
        isLoadingPurchaseBill: true,
      };
    }

    case actionTypes.GET_PURCHASE_BILL_DUMP_RECYCLING_SUCCESS: {
      return {
        ...state,
        purchaseBillDumpRecycling: action.data,
        isLoadingPurchaseBill: false,
      };
    }

    case actionTypes.GET_PURCHASE_BILL_DUMP_RECYCLING_FAIL: {
      return {
        ...state,
        isLoadingPurchaseBill: false,
      };
    }    
    

    
    
    case actionTypes.LOAD_STORE_RECEIPT_LIST:{
      let storeReceiptList = cloneDeep(state.storeReceiptList);
  
      storeReceiptList.isLoading = true;

      if (action.data.offset === 0) {
        storeReceiptList.data = [];
      }

      return {
        ...state,
        isLoadingStoreReceiptList: true,
        storeReceiptList,
        isRefreshingStoreReceiptList: (action.data.offset > 0) ? true : false
      };
    }

    case actionTypes.LOAD_STORE_RECEIPT_LIST_SUCCESS: {
      let storeReceiptList = {};
      
      if (action.data.limit === undefined) {
        storeReceiptList = cloneDeep(state.storeReceiptList);
        storeReceiptList.data = action.data.data;
      } else {
        storeReceiptList.offset = -1;

        // First time load / Refresh
        if (action.data.offset === 0) {
          storeReceiptList.data = action.data.data;
          storeReceiptList.length = action.data.total_result;
        } else {
          if (action.data.total_result === 0) {
            storeReceiptList.data = [...state.storeReceiptList.data];
            storeReceiptList.offset = action.data.offset;
          } else {
            let temp = [...state.storeReceiptList.data];
            let old_key = Object.keys(temp);
            Object.keys(action.data.data).forEach((val) => {
              if (old_key.includes(val)) {
                temp.push(action.data.data[val]);
              }
            });

            storeReceiptList.data = temp;
            storeReceiptList.length = state.storeReceiptList.length + action.data.total_result;
          }
        }
        if (action.data.total_result < action.data.limit) {
          storeReceiptList.offset = storeReceiptList.length;
        }
      }
  
      storeReceiptList.isLoading = false;
      storeReceiptList.total = action.data.total;
      storeReceiptList.hasMore = false;
      
      if(storeReceiptList.length < storeReceiptList.total)
      {
        storeReceiptList.hasMore = true;
      }

      return {
        ...state,
        isLoadingStoreReceiptList: false,
        storeReceiptList
      };
    }

    case actionTypes.LOAD_STORE_RECEIPT_LIST_FAIL:{
      let storeReceiptList = cloneDeep(state.storeReceiptList);
      storeReceiptList.isLoading = false;

      return {
        ...state,
        storeReceiptList,
        isLoadingStoreReceiptList: false,
      };
    }



    case actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS: {
      return {
        ...state,
        storeReceiptDumpExpress: null,
        isLoadingStoreReceipt: true,
      };
    }

    case actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS_SUCCESS: {
      return {
        ...state,
        storeReceiptDumpExpress: action.data,
        isLoadingStoreReceipt: false,
      };
    }

    case actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS_FAIL: {
      return {
        ...state,
        isLoadingStoreReceipt: false,
      };
    }    


    case actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING: {
      return {
        ...state,
        storeReceiptDumpRecycling: null,
        isLoadingStoreReceipt: true,
      };
    }

    case actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING_SUCCESS: {
      return {
        ...state,
        storeReceiptDumpRecycling: action.data,
        isLoadingStoreReceipt: false,
      };
    }

    case actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING_FAIL: {
      return {
        ...state,
        isLoadingStoreReceipt: false,
      };
    }    


    case actionTypes.LOAD_PURCHASE_BILL_MONTH: {
      return {
        ...state,
        purchaseBillMonth: {},
        isLoadingPurchaseBillMonth: true,
      };
    }

    case actionTypes.LOAD_PURCHASE_BILL_MONTH_SUCCESS: {
      return {
        ...state,
        purchaseBillMonth: action.data,
        isLoadingPurchaseBillMonth: false,
      };
    }

    case actionTypes.LOAD_PURCHASE_BILL_MONTH_FAIL: {
      return {
        ...state,
        isLoadingPurchaseBillMonth: false,
      };
    }    
    



    case actionTypes.LOAD_PURCHASE_BILL_SUMMARY: {
      return {
        ...state,
        purchaseBillSummary: null,
        isLoadingPurchaseBillSummary: true,
      };
    }

    case actionTypes.LOAD_PURCHASE_BILL_SUMMARY_SUCCESS: {
      return {
        ...state,
        purchaseBillSummary: action.data,
        isLoadingPurchaseBillSummary: false,
      };
    }

    case actionTypes.LOAD_PURCHASE_BILL_SUMMARY_FAIL: {
      return {
        ...state,
        isLoadingPurchaseBillSummary: false,
      };
    }  
    

    case actionTypes.LOAD_STOCK_RECORD_SUMMARY: {
      return {
        ...state,
        stockRecordSummary: null,
        isLoadingStockRecordSummary: true,
      };
    }

    case actionTypes.LOAD_STOCK_RECORD_SUMMARY_SUCCESS: {
      return {
        ...state,
        stockRecordSummary: action.data,
        isLoadingStockRecordSummary: false,
      };
    }

    case actionTypes.LOAD_STOCK_RECORD_SUMMARY_FAIL: {
      return {
        ...state,
        isLoadingStockRecordSummary: false,
      };
    }  
    

    case actionTypes.LOAD_STOCK_RECORD_LIST:{
      let storeRecordList = cloneDeep(state.storeRecordList);
  
      storeRecordList.isLoading = true;

      if (action.data.offset === 0) {
        storeRecordList.data = [];
      }

      return {
        ...state,
        isLoadingStoreRecordList: true,
        storeRecordList,
        isRefreshingStoreRecordList: (action.data.offset > 0) ? true : false
      };
    }

    case actionTypes.LOAD_STOCK_RECORD_LIST_SUCCESS: {
      let storeRecordList = {};
      
      if (action.data.limit === undefined) {
        storeRecordList = cloneDeep(state.storeRecordList);
        storeRecordList.data = action.data.data;
      } else {
        storeRecordList.offset = -1;

        // First time load / Refresh
        if (action.data.offset === 0) {
          storeRecordList.data = action.data.data;
          storeRecordList.length = action.data.total_result;
        } else {
          if (action.data.total_result === 0) {
            storeRecordList.data = [...state.storeRecordList.data];
            storeRecordList.offset = action.data.offset;
          } else {
            let temp = [...state.storeRecordList.data];
            let old_key = Object.keys(temp);
            Object.keys(action.data.data).forEach((val) => {
              if (old_key.includes(val)) {
                temp.push(action.data.data[val]);
              }
            });

            storeRecordList.data = temp;
            storeRecordList.length = state.storeRecordList.length + action.data.total_result;
          }
        }
        if (action.data.total_result < action.data.limit) {
          storeRecordList.offset = storeRecordList.length;
        }
      }
  
      storeRecordList.isLoading = false;
      storeRecordList.total = action.data.total;
      storeRecordList.hasMore = false;
      
      if(storeRecordList.length < storeRecordList.total)
      {
        storeRecordList.hasMore = true;
      }

      return {
        ...state,
        isLoadingStoreRecordList: false,
        storeRecordList
      };
    }

    case actionTypes.LOAD_STOCK_RECORD_LIST_FAIL:{
      let storeRecordList = cloneDeep(state.storeRecordList);
      storeRecordList.isLoading = false;

      return {
        ...state,
        storeRecordList,
        isLoadingStoreRecordList: false,
      };
    }


    case actionTypes.LOAD_MONTHLY_STOCK_REPORT: {
      return {
        ...state,
        monthlyStockReportList: [],
        isLoadingMonthlyStockReportList: true,
      };
    }

    case actionTypes.LOAD_MONTHLY_STOCK_REPORT_SUCCESS: {
      return {
        ...state,
        monthlyStockReportList: action.data,
        isLoadingMonthlyStockReportList: false,
      };
    }

    case actionTypes.LOAD_MONTHLY_STOCK_REPORT_FAIL: {
      return {
        ...state,
        isLoadingMonthlyStockReportList: false,
      };
    }  
    
    
    case actionTypes.GET_MONTHLY_STOCK_REPORT_DOC: {
      return {
        ...state,
        isLoadingMonthlyStockReportDoc: true,
      };
    }

    case actionTypes.GET_MONTHLY_STOCK_REPORT_DOC_SUCCESS: {
      return {
        ...state,
        isLoadingMonthlyStockReportDoc: false,
      };
    }

    case actionTypes.GET_MONTHLY_STOCK_REPORT_DOC_FAIL: {
      return {
        ...state,
        isLoadingMonthlyStockReportDoc: false,
      };
    }  
    
    
    case actionTypes.LOAD_DAILY_SETTLEMENT_DETAIL: {
      return {
        ...state,
        dailySettlement: null,
        isLoadingDailySettlementDetail: true,
      };
    }

    case actionTypes.LOAD_DAILY_SETTLEMENT_DETAIL_SUCCESS: {
      return {
        ...state,
        dailySettlement: action.data,
        isLoadingDailySettlementDetail: false,
      };
    }

    case actionTypes.LOAD_DAILY_SETTLEMENT_DETAIL_FAIL: {
      return {
        ...state,
        isLoadingDailySettlementDetail: false,
      };
    } 
    
    
    case actionTypes.COMPLETE_DAILY_SETTLEMENT: {
      return {
        ...state,
        isUpdateDailySettlementSuccess: false,
        isUpdateDailySettlementFailed: false,
        isProcessUpdateDailySettlement: true,
      };
    }

    case actionTypes.COMPLETE_DAILY_SETTLEMENT_SUCCESS: {
      return {
        ...state,
        isUpdateDailySettlementSuccess: true,
        isProcessUpdateDailySettlement: false,
      };
    }

    case actionTypes.COMPLETE_DAILY_SETTLEMENT_FAIL: {
      return {
        ...state,
        isUpdateDailySettlementFailed: true,
        isProcessUpdateDailySettlement: false,
      };
    } 
    

    default:
      return state;
  }
}
