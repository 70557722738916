import * as actionTypes from "./actionTypes";



export const loadZoneCount = data => {
  
  return {
    type: actionTypes.LOAD_ZONE_COUNT,
    data
  };
};

export const loadZoneCountSuccess = data => {
  return {
    type: actionTypes.LOAD_ZONE_COUNT_SUCCESS,
    data
  };
};

export const loadZoneCountFail = () => {
  return {
    type: actionTypes.LOAD_ZONE_COUNT_FAIL
  };
};

export const loadZoneList = data => {
  
  return {
    type: actionTypes.LOAD_ZONE_LIST,
    data
  };
};

export const loadZoneListSuccess = data => {
  return {
    type: actionTypes.LOAD_ZONE_LIST_SUCCESS,
    data
  };
};

export const loadZoneListFail = () => {
  return {
    type: actionTypes.LOAD_ZONE_LIST_FAIL
  };
};


export const loadZoneDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_ZONE_DETAIL,
    id,
    data
  };
};

export const loadZoneDetailSuccess = data => {
  return {
    type: actionTypes.LOAD_ZONE_DETAIL_SUCCESS,
    data
  };
};

export const loadZoneDetailFail = () => {
  return {
    type: actionTypes.LOAD_ZONE_DETAIL_FAIL
  };
};



export const createZone = (data) => {
  return {
    type: actionTypes.CREATE_ZONE,
    data
  };
};

export const createZoneSuccess = () => {
  return {
    type: actionTypes.CREATE_ZONE_SUCCESS
  };
};

export const createZoneFail = () => {
  return {
    type: actionTypes.CREATE_ZONE_FAIL
  };
};



export const updateZone = (id,data) => {
  return {
    type: actionTypes.UPDATE_ZONE,
    id,
    data
  };
};

export const updateZoneSuccess = () => {
  return {
    type: actionTypes.UPDATE_ZONE_SUCCESS
  };
};

export const updateZoneFail = () => {
  return {
    type: actionTypes.UPDATE_ZONE_FAIL
  };
};



export const delZone = (id,data) => {
  
  return {
    type: actionTypes.REMOVE_ZONE,
    id,
    data
  };
};

export const delZoneSuccess = () => {
  
  return {
    type: actionTypes.REMOVE_ZONE_SUCCESS,
  };
};

export const delZoneFail = () => {
  return {
    type: actionTypes.REMOVE_ZONE_FAIL
  };
};



export const updateZonePlcement = (id,data) => {
  return {
    type: actionTypes.UPDATE_ZONE_PLACEMENT,
    id,
    data
  };
};

export const updateZonePlcementSuccess = () => {
  return {
    type: actionTypes.UPDATE_ZONE_PLACEMENT_SUCCESS
  };
};

export const updateZonePlcementFail = () => {
  return {
    type: actionTypes.UPDATE_ZONE_PLACEMENT_FAIL
  };
};



export const updateZoneFullyFilled = (id, data) => {
  return {
    type: actionTypes.UPDATE_ZONE_FULLY_FILLED,
    id,
    data
  };
};

export const updateZoneFullyFilledSuccess = () => {
  return {
    type: actionTypes.UPDATE_ZONE_FULLY_FILLED_SUCCESS
  };
};

export const updateZoneFullyFilledFail = () => {
  return {
    type: actionTypes.UPDATE_ZONE_FULLY_FILLED_FAIL
  };
};


export const updateZoneCollection = (id, data) => {
  return {
    type: actionTypes.UPDATE_ZONE_COLLECTION,
    id,
    data
  };
};

export const updateZoneCollectionSuccess = () => {
  return {
    type: actionTypes.UPDATE_ZONE_COLLECTION_SUCCESS
  };
};

export const updateZoneCollectionFail = () => {
  return {
    type: actionTypes.UPDATE_ZONE_COLLECTION_FAIL
  };
};


export const loadDumpsterHistoryList = (data) => {
  return {
    type: actionTypes.LOAD_DUMPSTER_HISTORY_LIST,
    data
  };
};

export const loadDumpsterHistoryListSuccess = (data) => {
  return {
    type: actionTypes.LOAD_DUMPSTER_HISTORY_LIST_SUCCESS,
    data
  };
};

export const loadDumpsterHistoryListFail = () => {
  return {
    type: actionTypes.LOAD_DUMPSTER_HISTORY_LIST_FAIL
  };
};




export const loadExpressPackingList = (id, data) => {
  return {
    type: actionTypes.LOAD_EXPRESS_PACKING_LIST,
    id,
    data
  };
};

export const loadExpressPackingListSuccess = (data) => {
  return {
    type: actionTypes.LOAD_EXPRESS_PACKING_LIST_SUCCESS,
    data
  };
};

export const loadExpressPackingListFail = () => {
  return {
    type: actionTypes.LOAD_EXPRESS_PACKING_LIST_FAIL
  };
};




export const generateRecyclingPackingList = (id, data) => {
  return {
    type: actionTypes.GENERATE_RECYCLING_PACKING_LIST,
    id,
    data
  };
};

export const generateRecyclingPackingListSuccess = (data) => {
  return {
    type: actionTypes.GENERATE_RECYCLING_PACKING_LIST_SUCCESS,
    data
  };
};

export const generateRecyclingPackingListFail = () => {
  return {
    type: actionTypes.GENERATE_RECYCLING_PACKING_LIST_FAIL
  };
};




export const loadRecyclingPackingHistoryList = (data) => {
  return {
    type: actionTypes.LOAD_RECYCLING_PACKING_HISTORY_LIST,
    data
  };
};

export const loadRecyclingPackingHistoryListSuccess = (data) => {
  return {
    type: actionTypes.LOAD_RECYCLING_PACKING_HISTORY_LIST_SUCCESS,
    data
  };
};

export const loadRecyclingPackingHistoryListFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_PACKING_HISTORY_LIST_FAIL
  };
};


export const loadRecyclingPackingHistoryDetail = (id, data) => {
  return {
    type: actionTypes.LOAD_RECYCLING_PACKING_HISTORY_DETAIL,
    id,
    data
  };
};

export const loadRecyclingPackingHistoryDetailSuccess = (data) => {
  return {
    type: actionTypes.LOAD_RECYCLING_PACKING_HISTORY_DETAIL_SUCCESS,
    data
  };
};

export const loadRecyclingPackingHistoryDetailFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_PACKING_HISTORY_DETAIL_FAIL
  };
};