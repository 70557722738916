import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import { push } from "react-router-redux";
import Helper from "../../helpers/helpers";

const delay = (ms) => new Promise(res => setTimeout(res, ms))

export function* loadGraderList() {
  
  yield takeEvery(actionTypes.LOAD_GRADER_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GRADERLIST");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadGraderList(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
          
          let params = {
            data: response.data.data,
            offset: action.data.offset,
            limit: action.data.limit,
            total_result: response.data.data.length,
            total: response.data.total,
          }

          yield put(actions.loadGraderListSuccess(params));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadGraderListFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadGraderListFail());
    }
  });
}


export function* loadGraderDetail() {
  
  yield takeEvery(actionTypes.LOAD_GRADER_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GRADERDETAIL");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadGraderDetail(action.id, action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          yield put(actions.loadGraderDetailSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadGraderDetailFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadGraderDetailFail());
    }
  });
}

export function* createGrader() {
  yield takeEvery(actionTypes.CREATE_GRADER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CREATEGRADER");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.createGrader(action.data);
      if(response)
      {
        if (response.data.success) {

          yield delay(1000);
          yield put(actions.createGraderSuccess());
          yield put(actions.msgSuccess(response.data.message));
          yield put(push("/grader"));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.createGraderFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.createGraderFail());
    }
  });
}

export function* updateGrader() {
  yield takeEvery(actionTypes.UPDATE_GRADER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDATEGRADER");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.updateGrader(action.id, action.data);
      if(response)
      {
        if (response.data.success) {
          
          yield delay(1000);
          yield put(actions.updateGraderSuccess());
          yield put(actions.msgSuccess(response.data.message));
          yield put(push("/grader"));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.updateGraderFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.updateGraderFail());
    }
  });
}

export function* delGrader() {
  
  yield takeEvery(actionTypes.REMOVE_GRADER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REMOVEGRADER");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    try {
      const response = yield API.removeGrader(action.id, action.data);
      if(response)
      {
        if (response.data.success) {
          
          yield delay(1000);
          yield put(actions.delGraderSuccess());
          yield put(actions.msgSuccess(response.data.message));
          yield put(push("/grader"));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.delGraderFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.delGraderFail());
    }
  });
}

export function* loadAnnouncement() {
  
  yield takeEvery(actionTypes.LOAD_ANNOUNCEMENT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("LOADANNOUNCEMENT");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadAnnouncement(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
    
          yield put(actions.loadAnnouncementSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadAnnouncementFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadAnnouncementFail());
    }
  });
}

export function* switchLanguage() {
  yield takeEvery(actionTypes.SWITCH_LANGUAGE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SWITCHLANGUAGE");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.switchLanguage(action.id, action.data);
      if(response)
      {
        if (response.data.success) {
          yield put(actions.switchLanguageSuccess());
          yield put(actions.msgSuccess(response.data.message));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.switchLanguageFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.switchLanguageFail());
    }
  });
}


export default function* graderSaga() {
  
  yield all([fork(loadGraderList)]);
  yield all([fork(loadGraderDetail)]);
  yield all([fork(createGrader)]);
  yield all([fork(updateGrader)]);
  yield all([fork(delGrader)]);  
  yield all([fork(loadAnnouncement)]);
  yield all([fork(switchLanguage)]);
}
