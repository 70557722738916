import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/grader";
  // return "http://192.168.1.12:3301/grader"
};


export const createGrader = (data) => {
  const url = `${baseURL()}/user/create`;
  return axios.post(url, data);
};

export const updateGrader = (id, data) => {
  const url = `${baseURL()}/user/update/${id}`;
  return axios.post(url, data);
};

export const loadGraderList = data => {
  const url = `${baseURL()}/user/list`;
  return axios.post(url, data);
};

export const loadGraderDetail = (id, data) => {
  const url = `${baseURL()}/user/detail/${id}`;
  return axios.post(url, data);
};

export const removeGrader = (id, data) => {
  const url = `${baseURL()}/user/remove/${id}`;
  return axios.post(url, data);
};

export const loadAnnouncement = (data) => {
  const url = `${baseURL()}/announcement`;
  return axios.post(url, data);
};

export const switchLanguage = (data) => {
  const url = `${baseURL()}/lang`;
  return axios.post(url, data);
};

